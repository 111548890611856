@import "../../../../styles/variables";

.additionalInfo{
  padding-top: 25px;
}

.auditorInformation{
  overflow-wrap: anywhere;
}

.legalAnswers{
  overflow-wrap: anywhere;
}

.customTabMenu{
  background-color: transparent !important;
  border-bottom: 2px solid $SecondaryColor !important;
}

.addressContainer{
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
}

.currentAddress {
  background-color: #fd7567;
  color: $WhiteColor;
  font-size: 10px;
  font-weight: bold;
  padding: 2px;
}

.addressBox{
  background-color: transparent;
  border: 1px solid $GrayColor;
  border-radius: 0.6em;
  padding: 12px;
}

.currentAddressBox{
  background-color: $FogColor !important;
  border: 1px solid $AuxiliarColor;
  border-radius: 0.6em;
  padding: 12px;
}

.homeTitle{
  color: #fd7567;
  font-weight: bold;
  margin-bottom: 5px;
}

.termTitle{
  color: #67dddd;
  font-weight: bold;
  margin-bottom: 5px;
}

.tertiaryTitle{
  color: #e661ac;
  font-weight: bold;
  margin-bottom: 5px;
}

.patchName{
  background-color:#d4d4d4;
  color:#727272;
  font-size: 10px;
  font-weight: bold;
  padding: 2px;
}

.label{
  color: $AuxiliarColor;
}

.labelCurrent{
  color: $SecondaryColor;
  font-weight: bold;
}

.coordinates{
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 10px;
}

.addressContent{
  display: flex;
  flex-direction: column;}