@import '../../../../../styles/variables';
@import '../../../../../styles/_guide';

.capitalize {
  text-transform: capitalize;
}

.title {
  color: $SecondaryColor;
  padding-top: 0.5em;
}

.label {
  background-color: 1px solid $GrayColor !important;
  color: $SecondaryColor;
  font-weight: $Bold;
}
