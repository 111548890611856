@import '../../../../styles/guide';
@import '../../../../styles/variables';
@import '../../../../styles/servelegal-font-variables';

.content {
  align-items: center;
  color: #acacac;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.titleQuestion {
  color: $AuxiliarColor;
  display: block;
  font-size: 14px;
  font-style: normal;
  margin: 16px 0px 16px 0px;
  text-align: center;
}

.italicTitleQuestion {
  @extend .titleQuestion;
  font-style: italic;
}

.note {
  color: $AuxiliarColor;
  font-size: 12px;
  margin: 8px 0px 16px 0px;
  text-align: center;
  width: 100%;
}

.italicNote {
  @extend .note;
  font-style: italic;
}
