@import '../../../../styles/variables';

.tooltipContainer {
  background-color: white;
  border-radius: 3px;
  padding: 8px;
  position: absolute;

  h4 {
    font-size: 14px;
    margin-bottom: 0;
    padding: 6px 0 0 8px;
  }

  .success {
    color: $SuccessColor;
  }
  .error {
    color: $ErrorColor;
  }

  .tooltipContent {
    background: white;
    color: black;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    min-width: 300px;
    padding: 8px;

    p {
      display: grid;
      font-size: 14px;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 3px !important;
      padding: 0;
    }

    .auditorsContainer {
      .auditors {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    }
  }

  .auditorExtraInfo {
    border: 1px solid $HelpColor;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    padding: 6px 22px;
    .checks {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      list-style-type: none;
      padding: 0;

      li {
        text-align: center;
      }
    }
    .ctaContainer {
      display: flex;
      justify-content: center;
      padding-bottom: 12px;
      .whatsAppBtn {
        background-color: transparent;
        border: 1px solid $SecondaryColor;
        border-radius: "50%";
        color: $SecondaryColor;
        display: block;
        max-width: 200px;
        padding: 8px 16px !important;

        a {
          color: $SecondaryColor;
          text-decoration: none;
        }
      }
    }
  }
}
