.tab {
  overflow-x: auto;
  overflow-y: hidden;
}

.auditorHeader{
  align-items: center;
  display: flex;
  justify-content: center;
}

.auditorCard{
  align-items: center;
  display: flex!important;
  font-size: 20px;
  margin-bottom: 30px!important;
  padding: 10px!important;
}
