@import '../../../../../styles/guide';
@import '../../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}

.basicInfo {
  align-items: flex-start;
  color: $HelpColor;
  display: flex;
  width: auto;

  // Remove bottom and top margin
  & > img {
    margin: 0 !important;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
}

.auditor {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  width: -webkit-fill-available;

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
}

.namePhoneEmailContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 18px;

  @media screen and (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.label {
  background-color: white !important;
  border: 1px solid $GrayColor !important;
  border-radius: 18px !important;
}

.auditorInfo {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.preferedName {
  @extend %h2;
  color: $SecondaryColor;
  font-weight: normal;
  font-weight: bold;
}

.statistic {
  @extend %h5;
  display: flex;
  flex-direction: column;
}

.statisticTitle {
  color: $SecondaryColor;
  font-weight: bold;
}

.statisticValue {
  color: $AuxiliarColor;
}

.image {
  background-color: $SecondaryColor !important;
  margin: 20px 0;
}

.image:hover {
  cursor: zoom-in;
}

.notesRights {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.notesRightsTitle {
  @extend %h4;
  color: $SecondaryColor;
}

.flexContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  max-width: 800px;
  min-width: 500px;
}

.notesfield {
  textarea {
    border: 1px solid $GrayColor;
    border-radius: 0.6rem;
    height: 120px;
    padding: 10px;
    resize: none;
    width: 100%;
  }
}

.notesButton {
  max-width: 200px !important;
}

.rightsContainer {
  background-color: $FogColor;
  border: 1px solid $GrayColor;
  border-radius: 0.6em;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  height: 120px;
  padding: 12px;
}

.rights {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rightsTitle {
  @extend %h5;
  color: $SecondaryColor;
  font-weight: bold;
}

.rightsCheckboxContainer {
  @extend %h6;

  align-items: flex-start;
  color: $AuxiliarColor;
  display: flex;
  gap: 8px;
}

.rightsloader {
  margin-left: 0px;
}
