@import '../../../../../styles/variables';

.row {
  align-items: stretch !important;
  display: flex;
  gap: 1rem;
  justify-content: left;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.title {
  color: $SecondaryColor;
  padding-top: 0.5em;
}

.capitalize {
  text-transform: capitalize;
}

.label {
  @extend .capitalize;
  color: $SecondaryColor;
}

.columnWrapper {
  display: flex !important;
  flex: 1 1 0 !important;
  max-width: 50% !important;

  @media (max-width: 900px) {
    max-width: 100% !important;
  }
}

.container {
  align-items: center !important;
  aspect-ratio: 1/1 !important;
  border: 1px solid $SecondaryColor;
  border-radius: 0.5rem;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto;
  overflow: hidden;
  width: 100% !important;
}

.imageContainer {
  align-items: center !important;
  display: flex !important;
  height: 100% !important;
  justify-content: center !important;
  padding: 0.2rem !important;
  width: 100% !important;
}

.image {
  background-color: $SecondaryColor !important;
  display: block;
  height: auto !important;
  max-height: 95% !important;
  max-width: 95% !important;
  object-fit: contain !important;
  width: auto !important;
}

.image:hover {
  cursor: zoom-in;
}