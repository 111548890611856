/*******************************
        User Overrides
*******************************/
.ui.secondary.menu {
    .item {
      color: @secondaryColor !important;
      background-color: transparent;
      border-top-left-radius: 15px !important;
      border-top-right-radius: 15px !important;
      margin-right: 3px !important;
      border-top: 1px solid @secondaryColor !important;
      border-left: 1px solid @secondaryColor !important;
      border-right: 1px solid @secondaryColor !important;
      border-bottom: none !important;
      margin-bottom: 0px !important;
    }
    .item.active {
      color:white !important;
      background-color: @secondaryColor !important;
      font-weight: 400 !important;
      border: 1px solid @secondaryColor;
      cursor: default;
    }
  }
