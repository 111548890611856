@import "../../../../../../styles/guide";
@import '../../../../../../styles/servelegal-font-variables';

.columnsHeader {
  font-weight: bold;
  padding: 5px 0;
}

.columns {
  display: flex;
}

.column {
  height: 100%;
}

.questionType {
  align-items: center;
  background-color: $FogColor;
  border-radius: 4px;
  color: $SecondaryColor;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  justify-content: flex-start;
  margin-bottom: 16px;
  overflow-wrap:anywhere;
  padding: 12px;

  &:hover {
    background-color: $WhiteColor;
  }
}

.mileageIcon {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-list;
  }
}

.columnExport {
  margin: 0px !important;
  margin-right: 16px !important;
}

.columnAvailable {
  height: fit-content;
  margin: 0px !important;
  position: initial !important;
  width: fit-content !important;
}
.columnAvailable:nth-child(2) {
  background-color: $GrayColor;
}
