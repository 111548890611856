@import '../../../styles/guide';
@import '../../../styles/variables';

.root {
  align-items: center;
  display: flex;
  gap: 10px;
}

.label {
  @extend %h6;
  color: $HelpColor;
  cursor: pointer;
}
