@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  p {
    margin-bottom: 56px !important;
  }
}

.errorIcon{
  color: #c84858;
}